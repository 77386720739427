import { useState, useEffect } from 'react';
import { Theme, makeStyles, withStyles } from "@material-ui/core";
import Slider from "@material-ui/core/Slider";

const useStyles = makeStyles((theme: Theme) => ({
  SurveySliderComponent: {
    margin: '0 auto',
    width: 400,
    [theme.breakpoints.down(650)]: {
      width: '90%'
    },
  },
  imgContainer: {
    textAlign: 'center',
    width: 'auto'
  },
  img: {
    height: 120
  },
  valueText: {
   display: 'flex',
   justifyContent: 'center'
  }
}));

/*
Creates a Slider from Material UI and sets the color
*/
const CustomSlider = withStyles({
  rail: {
    backgroundImage: "linear-gradient(.25turn, #113869, #FAD201)"
  },
  track: {
    backgroundImage: "linear-gradient(.25turn, #113869, #FAD201)",
    height: 3
  },
  root: {
    marginTop: 10
  },
})(Slider);

interface ISurveySliderComponent {
  setMoodObject(key, value): void;
  disabled: boolean
}

const SurveySliderComponent = (props: ISurveySliderComponent) => {
    const classes = useStyles();
    const [value, setValue] = useState(3);
    const {
      disabled
    } = props

    useEffect(() => {
      props.setMoodObject('mood', value)
    }, [value]);

    const setSliderValue = (event, newValue) => {
      setValue(newValue);
    };

    function valueText(value) {
      if (value === 1) {
        return 'Sehr unzufrieden'
      } else if (value === 2) {
        return 'Unzufrieden'
      } else if (value === 3) {
        return 'Alles in Ordnung'
      } else if (value === 4) {
        return 'Zufrieden'
      } else if (value === 5) {
        return 'Sehr zufrieden'
      }
    }

  return (
    <div className={classes.SurveySliderComponent}>
      <div className={classes.imgContainer}>
        <img className={classes.img} src={`/mood-icons/mood-${value}.svg`} alt="Icon der Stimmung" />
      </div>
      <CustomSlider value={value} onChange={setSliderValue} marks min={1} max={5} disabled={disabled} />
      <div className={classes.valueText}>{valueText(value)}</div>
    </div>
  );
}

export default SurveySliderComponent;
