import { ResponsiveCalendar } from '@nivo/calendar'
import { BasicTooltip } from '@nivo/tooltip';
import { useEffect, useState } from 'react';
import { IChild } from '../../shared/interfaces/Child';
import { getDateChartMoodsByTeamID } from '../../shared/services/MoodService';
import {endOfYear, format, startOfYear} from "date-fns";
import { makeStyles, Theme } from '@material-ui/core';
import { IDateRange } from '../../shared/interfaces/DateRange';
import BeatLoaderComponent from '../SurveyContainerComponent/util/BeatLoader';

const useStyles = makeStyles((theme: Theme) => ({
    calendarChart: {
        height: 250,
        margin: '0 auto',
        width: "90%"
    },
    title: {
        marginBottom: 0,
        marginLeft: 20,
        paddingTop: 10
    }
}))

const CalendarChartComponent = (props: IChild) => {
    const classes = useStyles();
    const {
        currentUser
    } = props;
    const [chart, setChart] = useState < {
        day: string,
        value: number
    } [] > ([])
    const firstDayOfTheYear = format(startOfYear(new Date()), 'yyyy-MM-dd')
    const lastDayOfTheYear = format(endOfYear(new Date()), 'yyyy-MM-dd')

    useEffect(() => {
        if (currentUser) {
            getCalendarChartMoodsByTeamID();
        }

    }, [currentUser === undefined]);

    async function getCalendarChartMoodsByTeamID() {
        const dateRange: IDateRange = {
            startDate: firstDayOfTheYear,
            endDate: lastDayOfTheYear
        }

        await getDateChartMoodsByTeamID(currentUser, dateRange).then((result) => {
            const moods: {
                day: string,
                value: number
            } [] = JSON.parse(result.body)
            setChart(moods)
        })
    }

    const CalTooltip = (props) => {
        const day: string = format(new Date(props.date), 'dd.MM.yyyy');
        let moodTitle: string = ''

        if (props.value < 2) {
            moodTitle = 'Das Team ist unglücklich'
        } else if (props.value >= 2 && props.value < 3) {
            moodTitle = 'Das Team ist unzufrieden'
        } else if (props.value >= 3 && props.value < 4) {
            moodTitle = 'Das Team ist neutral'
        } else if (props.value >= 4 && props.value < 5) {
            moodTitle = 'Das Team ist zufrieden'
        } else {
            moodTitle = 'Das Team ist glücklich'
        }
        return ( <
            BasicTooltip id = {
                day
            }
            value = {
                moodTitle
            }
            color = {
                props.color
            }
            enableChip /
            >
        );
    };

    return (
        <>
            <h1 className={classes.title}>Jahresübersicht</h1>
            <div className={classes.calendarChart}>
                {
                    chart.length > 0 &&
                    <ResponsiveCalendar
                        data={chart}
                        from={firstDayOfTheYear}
                        to={lastDayOfTheYear}
                        emptyColor="#eeeeee"
                        minValue={1}
                        maxValue={5}
                        colors={[ '#113869', '#13515B', '#218167', '#78AD57', '#FAD201' ]}
                        margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
                        yearSpacing={40}
                        dayBorderWidth={2}
                        dayBorderColor="#ffffff"
                        monthBorderWidth={1}
                        monthBorderColor="#120d0d"
                        tooltip={CalTooltip}
                        legends={[
                            {
                                anchor: 'bottom-right',
                                direction: 'row',
                                translateY: 36,
                                itemCount: 4,
                                itemWidth: 42,
                                itemHeight: 36,
                                itemsSpacing: 14,
                                itemDirection: 'right-to-left'
                            }
                        ]}
                    />
                }

            { chart.length === 0 &&
                <BeatLoaderComponent />
            }
            </div>
        </>
    )
}

export default CalendarChartComponent