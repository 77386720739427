import { Button, makeStyles, Menu, MenuItem, Theme } from '@material-ui/core';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import MenuIcon from '@material-ui/icons/Menu';
import ThumbsUpDownIcon from '@material-ui/icons/ThumbsUpDown';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Auth from '@aws-amplify/auth';

const useStyles = makeStyles((theme: Theme) => ({
  menuButton: {
    margin: '10px 10px 0 0'
  },
  menu: {
    '& .MuiMenu-paper': {
      marginTop: 8,
    }
  },
  menuItemContent: {
    display: 'flex',
    flexDirection: 'row',
  },
  menuItemIcon: {
    marginRight: 15
  }
}));
const MobileMenuComponent = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  async function signOut() {
    try {
      await Auth.signOut();
      handleMenuClose()
      window.location.reload();
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }

  return (
    <div className="MobileMenuComponent">
      <Button className={classes.menuButton} onClick={handleMenuClick}>
        <MenuIcon style={{ color: '#113869' }} fontSize="large" />
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        className={classes.menu}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom',horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <MenuItem onClick={handleMenuClose} component={Link} to="/">
          <div className={classes.menuItemContent}>
            <ThumbsUpDownIcon style={{ color: '#113869' }} className={classes.menuItemIcon} />
            <div>
              Stimmung abgeben
            </div>
          </div>
          </MenuItem>
        <MenuItem onClick={handleMenuClose} component={Link} to="/overview">
          <div className={classes.menuItemContent}>
            <EqualizerIcon style={{ color: '#113869' }} className={classes.menuItemIcon} />
            <div>
              Übersicht
            </div>
          </div>
        </MenuItem>
        <MenuItem onClick={signOut}>
          <div className={classes.menuItemContent}>
            <ExitToAppIcon style={{ color: '#C92519' }} className={classes.menuItemIcon} />
            <div>
              Logout
            </div>
          </div>
          </MenuItem>
      </Menu>
    </div>
  );
}

export default MobileMenuComponent;
