import { Snackbar } from '@material-ui/core';
import { useEffect, useState} from 'react';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
       return <MuiAlert elevation={6} variant="filled" {...props} />;
}

interface ISnackbarComponent {
       type: string;
       message: string;
       show: boolean;
}

const SnackbarComponent = (props: ISnackbarComponent) => {
       const [open, setOpen] = useState(false);
       const { message, type, show } = props;

       useEffect(() => {
              setOpen(show);
       }, [show]);

       const handleClose = (event: any, reason: string) => {
              if (reason === 'clickaway') {
                     return;
              }

              setOpen(false);
       };

       return (
              <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
                     <Alert onClose={handleClose} severity={type}>
                     {message}
                     </Alert>
              </Snackbar>
       )
}

export default SnackbarComponent;