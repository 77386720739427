import Auth from "@aws-amplify/auth";
import MUIDataTable, { MUIDataTableOptions } from "mui-datatables";
import { useEffect, useState } from 'react'
import { User } from "../../../shared/interfaces/User";
import { getAllUsersByTeamId } from "../../../shared/services/UserService";
import { deleteUser } from "../../../shared/services/UserService";
import BeatLoaderComponent from "../../SurveyContainerComponent/util/BeatLoader";

const columns = [
    {
        name: "name",
        label: "E-Mail-Adresse",
        options: {
            filter: false,
            sort: true,
        }
    },
    {
    name: "role",
    label: "Rolle",
    options: {
        filter: true,
        sort: true,
    }
    },
];

interface IUsersList {
    currentUser: User
    snackbarStatus: boolean
    userCreationStatus: boolean
}

const UsersList = (props: IUsersList) => {
    const { currentUser, snackbarStatus, userCreationStatus } = props
    const [users, setUsers] = useState<User[]>([])
    const [userPoolId, setUserPoolId] = useState<string>('')

    useEffect(() => {
        getUsersByTeamId()
    }, [users.length === 0 || snackbarStatus])
    
    useEffect(() => {
        getUserPoolId()
    }, [userPoolId !== ''])

    async function getUsersByTeamId() {
        try {
            await getAllUsersByTeamId(currentUser.teamID).then((result: any) => {
                setUsers(result.data.listUsers.items)
            })
        } catch (error) {
            console.log(error)
        }
    }

    async function getUserPoolId() {
        const data = await Auth.currentUserPoolUser();
        setUserPoolId(data.pool.userPoolId)
    }


    const muiDataTableOptions: MUIDataTableOptions = {
        onRowsDelete: (e) => deleteSelectedUser(e.lookup),
        textLabels: {
            selectedRows: {
                text: "Zeilen ausgewählt",
                delete: "Löschen",
                deleteAria: "Lösche die ausgewählten Zeilen",
            },
            toolbar: {
                search: "Suchen",
                downloadCsv: "Download CSV",
                print: "Print",
                viewColumns: "Spalten anzeigen",
                filterTable: "Tabelle filtern",
            },
            pagination: {
                next: "Nächste Seite",
                previous: "Vorherige Seite",
                rowsPerPage: "Zeilen pro Seite:",
                displayRows: "von",
            },
            filter: {
                all: "Alle",
                title: "FILTER",
                reset: "ZURÜCKSETZEN",
            },
            viewColumns: {
                title: "Spalten anzeigen",
                titleAria: "Spalten anzeigen/verstecken",
            },
        },
        rowsPerPageOptions:[10, 20],
        download: false,
        print: false
    }


    function deleteSelectedUser(indices: any) {
        for (const [key, value] of Object.entries(indices)) {
            deleteUser(users[key].name, userPoolId, users[key].id).then((result) => {
                console.log('Benutzer wurde gelöscht.')
            }).catch((error) => {
                console.log('Löschen fehlgeschlagen: ', error)
            })
        }

    }

    return (
        <>
            {
                users.length > 0 && !userCreationStatus &&
                    <MUIDataTable
                    title={"Benutzer"}
                    data={users}
                    columns={columns}
                    options={muiDataTableOptions}
                    />
            }
            {
                (users.length === 0 || userCreationStatus) &&
                    <BeatLoaderComponent />
            }
        </>
    )
}

export default UsersList