/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getTeam = /* GraphQL */ `
  query GetTeam($id: ID!) {
    getTeam(id: $id) {
      id
      users {
        items {
          id
          teamID
          name
          cognitoID
          role
          createdAt
          updatedAt
        }
        nextToken
      }
      name
      description
      moods {
        items {
          id
          teamID
          mood
          comment
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listTeams = /* GraphQL */ `
  query ListTeams(
    $filter: ModelTeamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeams(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        users {
          nextToken
        }
        name
        description
        moods {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      teamID
      name
      cognitoID
      role
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        teamID
        name
        cognitoID
        role
        createdAt
        updatedAt
        userHasVotedToday
      }
      nextToken
    }
  }
`;
export const getMood = /* GraphQL */ `
  query GetMood($id: ID!) {
    getMood(id: $id) {
      id
      teamID
      mood
      comment
      createdAt
      updatedAt
    }
  }
`;
export const listMoods = /* GraphQL */ `
  query ListMoods(
    $filter: ModelMoodFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMoods(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        teamID
        mood
        comment
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
