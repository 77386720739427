import { makeStyles, Theme } from '@material-ui/core';
import {
    useEffect,
    useState
} from 'react'
import informationIcon from '../../../assets/informationIcon.svg';

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
    },
    text: {
        marginLeft: 20,
        marginRight: 20,
        marginTop: 20
    },
    img: {
        marginLeft: 20,
        marginTop: 20
    }
}))
export const ScreenSizeInformation = () => {
    const classes = useStyles()
    const [, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    function handleResize() {
        setWindowDimensions(getWindowDimensions());
    }

    function getWindowDimensions() {
        const {
            innerWidth: width,
            innerHeight: height
        } = window;
        return {
            width,
            height
        };
    }

    return ( 
        <> 
            {
                window.innerWidth < 400 &&
                <div className={classes.container}>
                    <img className={classes.img} src={informationIcon} alt="Hinweis Icon" />
                    <div className={classes.text}> Bitte drehe dein Gerät für eine optimale Anzeige der Seite.</div>
                </div>
            } 
        </>
    )
}

export default ScreenSizeInformation