/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createTeam = /* GraphQL */ `
  mutation CreateTeam(
    $input: CreateTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    createTeam(input: $input, condition: $condition) {
      id
      users {
        items {
          id
          teamID
          name
          cognitoID
          role
          createdAt
          updatedAt
        }
        nextToken
      }
      name
      description
      moods {
        items {
          id
          teamID
          mood
          comment
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateTeam = /* GraphQL */ `
  mutation UpdateTeam(
    $input: UpdateTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    updateTeam(input: $input, condition: $condition) {
      id
      users {
        items {
          id
          teamID
          name
          cognitoID
          role
          createdAt
          updatedAt
        }
        nextToken
      }
      name
      description
      moods {
        items {
          id
          teamID
          mood
          comment
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteTeam = /* GraphQL */ `
  mutation DeleteTeam(
    $input: DeleteTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    deleteTeam(input: $input, condition: $condition) {
      id
      users {
        items {
          id
          teamID
          name
          cognitoID
          role
          createdAt
          updatedAt
        }
        nextToken
      }
      name
      description
      moods {
        items {
          id
          teamID
          mood
          comment
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      teamID
      name
      cognitoID
      role
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      teamID
      name
      cognitoID
      role
      createdAt
      updatedAt
      userHasVotedToday
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      teamID
      name
      cognitoID
      role
      createdAt
      updatedAt
    }
  }
`;
export const createMood = /* GraphQL */ `
  mutation CreateMood(
    $input: CreateMoodInput!
    $condition: ModelMoodConditionInput
  ) {
    createMood(input: $input, condition: $condition) {
      id
      teamID
      mood
      comment
      createdAt
      updatedAt
    }
  }
`;
export const updateMood = /* GraphQL */ `
  mutation UpdateMood(
    $input: UpdateMoodInput!
    $condition: ModelMoodConditionInput
  ) {
    updateMood(input: $input, condition: $condition) {
      id
      teamID
      mood
      comment
      createdAt
      updatedAt
    }
  }
`;
export const deleteMood = /* GraphQL */ `
  mutation DeleteMood(
    $input: DeleteMoodInput!
    $condition: ModelMoodConditionInput
  ) {
    deleteMood(input: $input, condition: $condition) {
      id
      teamID
      mood
      comment
      createdAt
      updatedAt
    }
  }
`;
