/* eslint-disable react-hooks/exhaustive-deps */
import { Button, makeStyles, Theme } from '@material-ui/core';
import { useState, useEffect } from 'react';
import SurveyCommentComponent from '../SurveyCommentComponent/SurveyCommentComponent';
import { Mood } from '../../shared/interfaces/Mood';
import SurveySliderComponent from '../SurveySliderComponent/SurveySliderComponent';
import SnackbarComponent from '../SnackbarComponent/SnackbarComponent';
import { getUserByCognitoId, updateUserHasVotedToday } from '../../shared/services/UserService';
import { User } from '../../shared/interfaces/User';
import { createMoodInDb } from '../../shared/services/MoodService';
import UserHasVoted from './util/UserHasVoted';
import BeatLoaderComponent from './util/BeatLoader';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: 540,
    backgroundColor: 'white',
    borderRadius: '0.5625rem',
    boxShadow: ' 0 1.5rem 2rem -0.5rem rgba(0, 0, 0, 0.07), 0 0.25rem 2rem 0 rgba(0, 0, 0, 0.03)',
    display: 'flex',
    flexDirection: 'column',
    margin: '100px auto',
    width: 600,
    [theme.breakpoints.down(320)]: {
      margin: '35px auto',
      maxWidth: 300
    },
    [theme.breakpoints.between(321, 375)]: {
      margin: '35px auto',
      maxWidth: 345
    },
    [theme.breakpoints.between(376, 500)]: {
      margin: '35px auto',
      maxWidth: 400
    },
    [theme.breakpoints.down(885)]: {
      margin: '32px auto',
      width: '80%'
    },
  },
  button_container: {
    textAlign: 'center',
    margin: '0 auto',
  },
  button: {
    color: 'white',
    backgroundColor: '#113869',
    borderRadius: '0.5625rem',
    fontFamily: 'MPLUS1p-Regular',
    fontWeight: 800,
    marginRight: 10,
    marginTop: 10,
    padding: '10px 25px',
    textAlign: 'center',
  },
  votedContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    height: 540,
    backgroundColor: 'white',
    borderRadius: '0.5625rem',
    boxShadow: ' 0 1.5rem 2rem -0.5rem rgba(0, 0, 0, 0.07), 0 0.25rem 2rem 0 rgba(0, 0, 0, 0.03)',
    display: 'flex',
    flexDirection: 'column',
    margin: '100px auto',
    width: 600,
    [theme.breakpoints.down(320)]: {
      margin: '35px auto',
      maxWidth: 300
    },
    [theme.breakpoints.between(321, 375)]: {
      margin: '35px auto',
      maxWidth: 345
    },
    [theme.breakpoints.between(376, 500)]: {
      margin: '35px auto',
      maxWidth: 400
    },
    [theme.breakpoints.down(500)]: {
      justifyConent: 'inherit',
    },
    [theme.breakpoints.down(885)]: {
      margin: '32px auto',
      width: '80%'
    },
  }
}));

interface ISurveyContainerComponent {
  cognitoId: string
  teamId: string
  userId: string
}

const SurveyContainerComponent = (props: ISurveyContainerComponent) => {
    const classes = useStyles();
    const {
      cognitoId,
      teamId,
      userId
    } = props;
    const [mood, setMood] = useState < Mood > ({
      mood: 3,
      comment: '',
      teamID: teamId,
      createdAt: '',
      updatedAt: ''
    })
    const [snackbar, setSnackbar] = useState({
      message: '',
      type: '',
      open: false,
    });
    const [user, setUser] = useState < User > ()
    
    useEffect(() => {
      getUser()
    }, [props])

    function setMoodObject(key, value) {
      setMood({
        ...mood,
        [key]: value
      })
    }

    async function addMood() {
      mood.createdAt = new Date().toISOString()
      mood.updatedAt = new Date().toISOString()

      try {
        await createMoodInDb(mood)
        await updateUserHasVotedToday(userId)
        await getUser()

        updateSnackbar(true);
      } catch (error) {
        console.log('error creating mood:', error)
        updateSnackbar(false);
      }
    }

    async function getUser() {
      await getUserByCognitoId(cognitoId).then((result: any) => {
        setUser(result.data.listUsers.items[0])
      })
    }

    function updateSnackbar(success: boolean) {
      if (success) {
        setSnackbar({
          message: 'Deine Stimmung wurde erfolgreich gespeichert.',
          type: 'success',
          open: true,
        });
      } else {
        setSnackbar({
          message: 'Deine Stimmung konnte nicht gespeichert werden.',
          type: 'error',
          open: true,
        });
      }
    }

  return (
    <div className="SurveyContainerComponent">
      {
        user && !user.userHasVotedToday &&
          <div className={classes.container}>
            <div>
              <div className={classes.button_container}>
                <h1>Wie geht es dir heute?</h1>
              </div>
              <SurveySliderComponent setMoodObject={setMoodObject} disabled={user.userHasVotedToday} />
              <SurveyCommentComponent setMoodObject={setMoodObject} disabled={user.userHasVotedToday} />
              <div className={classes.button_container}>
                <Button className={classes.button} type="submit" onClick={addMood}
                  disabled={user.userHasVotedToday}>Stimmung abgeben</Button>
              </div>
            </div>
            { !user &&
              <BeatLoaderComponent />
            }
          </div>
      }
      { user && user.userHasVotedToday &&
        <div className={classes.votedContainer}>
          <UserHasVoted />
        </div>
      }
      { snackbar.open && 
        <SnackbarComponent message={snackbar.message} type={snackbar.type} show={snackbar.open} />
      }
    </div>
  );
}

export default SurveyContainerComponent;
