import { makeStyles, TextField, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  SurveyCommentComponent: {
    margin: '20px auto',
    textAlign: 'center',
    width: 'auto',
    [theme.breakpoints.down(650)]: {
      margin: '0px auto',
    },
  },
  'MuiFormControl-root': {
    fontFamily: 'MPLUS1p-Regular',
    fontWeight: 800,
    width: 400,
  },
  textField: {
    width: 405,
    [theme.breakpoints.down(650)]: {
      margin: '15px auto',
      width: '90%'
    }
  }
}));

interface ISurveyCommentComponent {
  setMoodObject(key, value): void;
  disabled: boolean
}

const SurveyCommentComponent = (props: ISurveyCommentComponent) => {
    const classes = useStyles();
    const {
      disabled
    } = props

    const handleChange = (event) => {
      props.setMoodObject('comment', event.target.value)
    };

  return (
    <div className={classes.SurveyCommentComponent}>
      <TextField
        className={classes.textField}
        id="comments"
        label="Bitte beschreibe deine Stimmung"
        multiline
        rows={4}
        variant="outlined"
        onChange={handleChange}
        style={{ textAlign: 'center' }}
        disabled={disabled}
      />
    </div>
  );
}

export default SurveyCommentComponent;
