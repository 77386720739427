import { makeStyles, Theme } from '@material-ui/core';


const useStyles = makeStyles((theme: Theme) => ({
    imgContainer: {
        textAlign: 'center',
        width: 'auto',
        [theme.breakpoints.down(500)]: {
            fontSize: 12
        }
    },
    img: {
        height: 120,
        [theme.breakpoints.down(500)]: {
            margin: '0px auto',
        },
    },
}))

const UserHasVoted = () => {
    const classes = useStyles()

    return (
        <div className={classes.imgContainer}>
            <img className={classes.img} src={`/mood-icons/mood-3.svg`} alt="Icon der Stimmung" />
            <h1>Vielen Dank für deine Stimme! Du kannst morgen wieder abstimmen.</h1>
        </div>
    )
}

export default UserHasVoted