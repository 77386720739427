import { useState } from 'react';
import { Button, makeStyles, TextField, Theme } from "@material-ui/core";
import { IChild } from "../../shared/interfaces/Child"
import { addUser } from '../../shared/services/UserService';
import SnackbarComponent from '../SnackbarComponent/SnackbarComponent';
import UsersList from './partials/UsersList';

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        background: '#fff',
        borderRadius: '0.5625rem',
        boxShadow: '0 1.5rem 2rem -0.5rem rgba(0, 0, 0, 0.07), 0 0.25rem 2rem 0 rgba(0, 0, 0, 0.03)',
        height: 750,
        margin: '20px auto',
        maxWidth: 1152,
        paddingTop: 1,
        width: '80%',
        [theme.breakpoints.down(320)]: {
            margin: '35px auto',
            maxWidth: 300,
            width: 'auto'
        },
        [theme.breakpoints.between(321, 375)]: {
            margin: '35px auto',
            maxWidth: 345,
            width: 'auto'
        },
        [theme.breakpoints.between(376, 500)]: {
            margin: '35px auto',
            maxWidth: 400,
            width: 'auto'
        }
    },
    title: {
        textAlign: 'center'
    },
    createUserContainer: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
    },
    textField: {
        width: 400
    },
    button: {
        color: 'white',
        backgroundColor: '#113869',
        borderRadius: '0.5625rem',
        fontFamily: 'MPLUS1p-Regular',
        fontWeight: 800,
        marginRight: 10,
        marginTop: 10,
        padding: '10px 25px',
        textAlign: 'center',
        width: 200
    }
}))

const AdministratorComponent = (props: IChild) => {
    const classes = useStyles();
    const { currentUser } = props;
    const [username, setUsername] = useState<string>('');
    const [snackbar, setSnackbar] = useState({
        message: '',
        type: '',
        open: false,
    });
    const [userCreationStatus, setUserCreationStatus] = useState<boolean>(false)

    async function createUser() {
        try {
            setUserCreationStatus(true)
            await addUser(currentUser, username).then((result) => {
                if (result.statusCode === 200) {
                    updateSnackbar(true)
                } else {
                    updateSnackbar(false, result.errorMessage)
                }
            })
        } catch (error) {
            updateSnackbar(false, error);
        }
        setUserCreationStatus(false)
    }

    function updateSnackbar(success: boolean, error?: string) {
        if (success) {
            setSnackbar({
                message: 'Der Benutzer wurde efolgreich angelegt',
                type: 'success',
                open: true,
            });
            
        } else {
            setSnackbar({
                message: error,
                type: 'error',
                open: true,
            });
        }

        hideSnackbarAfterDuration(4000)
    }

    function hideSnackbarAfterDuration(duration: number){
        setTimeout(function(){ 
            setSnackbar({
                message: '',
                type: '',
                open: false,
            })
        }, duration);
    }
    
    return (
        <div className={classes.container}>
            <h1 className={classes.title}>Füge einen Mitarbeiter zu deinem Team hinzu</h1>
            <div className={classes.createUserContainer}>
                <TextField className={classes.textField} variant="outlined" label="E-Mail-Adresse eingeben" onChange={e => setUsername(e.target.value)} />
                <Button className={classes.button} onClick={async () => await createUser()} variant="outlined">Benutzer anlegen</Button>
            </div>
            <UsersList currentUser={currentUser} snackbarStatus={snackbar.open} userCreationStatus={userCreationStatus} />
            { snackbar.open
            && (
                <SnackbarComponent
                    message={snackbar.message}
                    type={snackbar.type}
                    show={snackbar.open}
                />
            )}
        </div>
    )
}

export default AdministratorComponent