import './App.scss';
import { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import OverviewContainerComponent from './components/OverviewContainerComponent/OverviewContainerComponent';
import NavigationBar from './components/NavigationBar/NavigationBar';
import SurveyContainerComponent from './components/SurveyContainerComponent/SurveyContainerComponent';
import Amplify, { Auth, API, graphqlOperation} from "aws-amplify";
import { withAuthenticator } from '@aws-amplify/ui-react';
import { listUsers } from './graphql/queries';
import AdministratorComponent from './components/AdministratorComponent/AdministratorComponent';
import awsmobile from './aws-exports';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { User } from './shared/interfaces/User';

awsmobile.aws_cloud_logic_custom = [
  {
      "name": "teammood_prototyp",
      "endpoint": "https://neri32he55.execute-api.eu-central-1.amazonaws.com/develop",
      "region": "eu-central-1"
  }
]

Amplify.configure(awsmobile);

const App = () => {
  const [currentUser, setCurrentUser] = useState < User > ();
  const [cognitoID, setCognitoID] = useState < string > ('');

  useEffect(() => {
    console.log(typeof awsmobile)
    getUserInfo()
    if (cognitoID) {
      getUserByCognitoId()
    }
  }, [cognitoID === ''])

  async function getUserInfo() {
    const data = await Auth.currentUserInfo();
    setCognitoID(data.attributes.sub)
  }

  async function getUserByCognitoId() {
    try {
      const currUser: any = await API.graphql(graphqlOperation(listUsers, {
        filter: {
          cognitoID: {
            eq: cognitoID
          }
        }
      }))
      if (currUser) {
        setCurrentUser(currUser.data.listUsers.items[0])
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div className="background">
        { currentUser &&
        <Router>
          <NavigationBar currentUser={currentUser} />
          <Switch>
            <Route exact path="/" render={(props)=> (
              <SurveyContainerComponent {...props} teamId={currentUser.teamID} cognitoId={cognitoID}
                userId={currentUser.id} />)} />
              <Route exact path="/overview" render={(props)=> (
                <OverviewContainerComponent {...props} currentUser={currentUser} />)} />
                {
                  currentUser.role === 'Administrator' &&
                    <Route exact path="/administrator" render={(props)=> (
                      <AdministratorComponent {...props} currentUser={currentUser} />)} />
                }
          </Switch>
        </Router>
        }
      </div>
    </LocalizationProvider>
  );
}

export default withAuthenticator(App);
