import API from "@aws-amplify/api"
import { User } from "../interfaces/User"
import { graphqlOperation} from "aws-amplify";
import { listUsers } from '../../graphql/queries'
import { updateUser } from "../../graphql/mutations";

export async function addUser(currentUser: User, username: string): Promise < any > {
       const init = {
              body: {
                     email: username,
                     teamID: currentUser.teamID
              },
       };

       try {
              return await API.post(
                     'teammood_prototyp',
                     '/teammood/createCognitoUser',
                     init
              )
       } catch (error) {
              console.log(error)
       }
}

export async function getUserByCognitoId(cognitoID: string) {
       try {
              return await API.graphql(graphqlOperation(listUsers, {
                     filter: {
                            cognitoID: {
                                   eq: cognitoID
                            }
                     }
              }))
       } catch (error) {
              console.log(error)
       }
}

export async function getAllUsersByTeamId(teamId: string) {
       try {
              return await API.graphql(graphqlOperation(listUsers, {
                     filter: {
                            teamID: {
                                   eq: teamId
                            }
                     }
              }))
       } catch (error) {
              console.log(error)
       }
}

export async function updateUserHasVotedToday(userId: string) {
       await API.graphql(graphqlOperation(updateUser, {
              input: {
                     id: userId,
                     userHasVotedToday: true
              }
       }))
}

export async function deleteUser(email: string, userPoolId: string, userId: string): Promise < any > {
       const searchParams = {
              body: {
                     email,
                     userPoolId,
                     userId
              },
       };

       try {
              return await API.post(
                     'teammood_prototyp',
                     '/teammood/deleteUserFromCognitoAndDb',
                     searchParams
              )
       } catch (error) {
              console.log(error)
       }
}