import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { format } from 'date-fns';
import { de } from 'date-fns/locale';
import { getCommentsByTeamIdAndDateRange } from '../../../shared/services/MoodService';
import { IDateRange } from '../../../shared/interfaces/DateRange';
import { User } from '../../../shared/interfaces/User';
import { Comment, CommentByDay } from '../../../shared/interfaces/Comment';

interface ICommentTable {
    currentUser: User
    dateRange: Date[]
}

const CommentTable = (props: ICommentTable) => {
    const [commentRows, setCommentRows] = useState([])
    const {
        currentUser,
        dateRange
    } = props;
    const [comments, setComments] = useState < CommentByDay[] > ([])

    useEffect(() => {
        setTableContent()
    }, [comments, dateRange])

    useEffect(() => {
        if (currentUser) {
            getComments();
        }

    }, [currentUser === undefined, dateRange]);

    async function getComments() {
        const dates: IDateRange = {
            startDate: format(dateRange[0], "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
            endDate: format(dateRange[1], "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'")
        }

        await getCommentsByTeamIdAndDateRange(currentUser, dates).then((result) => {
            const unsortedComments = JSON.parse(result.body)
            const sortedComments = sortComments(unsortedComments)

            setComments(sortedComments)
        })
    }

    function sortComments(unsortedComments: CommentByDay[]) {
        return unsortedComments.sort((a, b) => {
            if (a.day > b.day) {
                return -1;
            }

            if (a.day < b.day) {
                return 1;
            }

            return 0;
        });
    }

    function setTableContent() {
        setCommentRows([]);

        for (const comment of comments) {
            const commentObject = []
            comment.comments.forEach((value, index) => {
                commentObject.push({
                    day: comment.day,
                    comment: value,
                    mood: comment.moods[index]
                })
            })

            setCommentRows(oldArray => [...oldArray, createData(comment.day, commentObject)])
        }
    }

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableBody>
                    {commentRows.map((row) => (
                    <Row key={row.id} row={row} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

function createData(day: string, commentsArray: Comment[]) {
    return {
        day,
        commentsArray
    };
}

function Row(props) {
    const {
        row
    } = props;
    const [open, setOpen] = useState(false);

    return (
        <>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell style={{ width: '5%'}}>
                    <IconButton aria-label="expand row" size="small" onClick={()=> setOpen(!open)}
                        >
                        {open ?
                        <KeyboardArrowUpIcon /> :
                        <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell>{format(new Date(row.day), 'EEEE, dd. MMMM yyyy', { locale: de})}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography variant="h6" gutterBottom component="div">
                                Abgegebene Stimmungen
                            </Typography>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Datum</TableCell>
                                        <TableCell>Kommentar</TableCell>
                                        <TableCell align="right">Stimmung</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row.commentsArray.map((historyRow) => (
                                    <TableRow key={historyRow.id}>
                                        <TableCell component="th" scope="row">
                                            {format(new Date(historyRow.day), 'dd.MM.yyyy')}
                                        </TableCell>
                                        <TableCell>{historyRow.comment}</TableCell>
                                        <TableCell align="right">{historyRow.mood}</TableCell>
                                    </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}

export default CommentTable