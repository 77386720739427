import { BeatLoader } from "react-spinners"
import { Theme } from "@mui/material"
import { makeStyles } from "@mui/styles"

const useStyles = makeStyles((theme: Theme) => ({
    loader: {
        textAlign: 'center',
        marginTop: 50
    },
}))
const BeatLoaderComponent = () => {
    const classes = useStyles()
    
    return (
        <div className={classes.loader}>
            <BeatLoader size={50} color="#113869" />
        </div>
    )
}

export default BeatLoaderComponent