import { useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { IChild } from '../../shared/interfaces/Child';
import CalendarChartComponent from './CalendarChartComponent';
import PieChartComponent from './PieChartComponent';
import CommentTable from './partials/CommentTable';
import { startOfWeek } from 'date-fns';
import ScreenSizeInformation from './partials/ScreenSizeInformation';

const useStyles = makeStyles((theme: Theme) => ({
  OverviewContainerComponent: {
    width: 'auto',
  },
  pieContainer: {
    background: '#fff',
    borderRadius: '0.5625rem',
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
    boxShadow: '0 1.5rem 2rem -0.5rem rgba(0, 0, 0, 0.07), 0 0.25rem 2rem 0 rgba(0, 0, 0, 0.03)',
    height: 1100,
    margin: '20px auto',
    maxWidth: 1152,
    width: '80%',
    [theme.breakpoints.down(320)]: {
      margin: '35px auto',
      width: '80%'
    },
    [theme.breakpoints.between(321, 412)]: {
      margin: '35px auto',
      width: '80%'
    },
    [theme.breakpoints.between(412, 500)]: {
      margin: '35px auto',
      maxWidth: 400,
      width: '80%'
    }
  },
  loader: {
    textAlign: 'center',
    marginTop: 50
  }
}));

const OverviewContainerComponent = (props: IChild) => {
  const classes = useStyles();
  const { currentUser } = props;
  const today: Date = new Date(new Date().setUTCHours(22, 59, 59, 999))
  const [dateRange, setDateRange] = useState([getMondayOfWeek(), today]);

  function getMondayOfWeek() {
    return startOfWeek(new Date().setUTCHours(23, 0, 0, 0), {
      weekStartsOn: 1
    });
  }

  return (
    <div className="OverviewContainerComponent">
      <div className={classes.pieContainer}>
        { currentUser &&
        <>
          <ScreenSizeInformation />
          <CalendarChartComponent currentUser={currentUser} />
          <PieChartComponent currentUser={currentUser} setDateRange={setDateRange} />
          <CommentTable currentUser={currentUser} dateRange={dateRange} />
        </>
        }
      </div>
    </div>
  );
}
export default OverviewContainerComponent;
