import { useState, useEffect } from 'react';
import logo from '../../assets/logo_btc.svg';
import { Button, makeStyles, Theme } from '@material-ui/core';
import { Tabs, Tab } from '@material-ui/core';
import { Link } from 'react-router-dom';
import MobileMenuComponent from '../MobileMenuComponent/MobileMenuComponent';
import { Auth } from 'aws-amplify';
import { IChild } from '../../shared/interfaces/Child';

const useStyles = makeStyles((theme: Theme) => ({
  NavigationBar: {
    backgroundColor: 'white',
    borderRadius: '0.5625rem',
    boxShadow: '0 1.5rem 2rem -0.5rem rgba(0,0,0,0.07), 0 0.25rem 2rem 0 rgba(0,0,0,0.03)',
    height: 64,
    margin: '30px auto',
    width: '80%',
    maxWidth: 1152,
  },
  navContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  mobileMenu: {
    display: 'none',
    [theme.breakpoints.down(885)]: {
      display: 'flex !important'
    },
  },
  logo: {
    height: 41,
    margin: 12,
    marginLeft: 16,
    width: 113,
  },
  navButtonContainer: {
    marginLeft: 10,
    [theme.breakpoints.down(885)]: {
      display: 'none !important'
    },
  },
  tab: {
    color: '#113869',
    fontFamily: 'MPLUS1p-Regular',
    fontSize: 16,
    fontWeight: 800,
    marginTop: 3,
    padding: '15px 32px',
    textAlign: 'center'
  },
  authButton: {
    color: 'white',
    backgroundColor: '#113869',
    borderRadius: '0.5625rem',
    fontFamily: 'MPLUS1p-Regular',
    fontWeight: 800,
    marginRight: 10,
    marginTop: 10,
    padding: '10px 25px',
    textAlign: 'center',
    [theme.breakpoints.down(885)]: {
      display: 'none !important'
    },
  }
}));

const underlineColor: string = "#113869";
const NavigationBar = (props: IChild) => {
  const classes = useStyles();
  const { currentUser } = props
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    setTabByUrl();
  }, [])

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  function setTabByUrl() {
    if (window.location.href.indexOf("overview") > -1) {
      setTabIndex(1)
    }

    if (currentUser.role === 'Administrator') {
      if (window.location.href.indexOf("administrator") > -1) {
        setTabIndex(2)
      }
    }
  }

  async function signOut() {
    try {
      await Auth.signOut();
      window.location.reload();
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }

  function userIsAdmin() {
    if (currentUser.role === 'Administrator') {
      return true
    }

    return false
  }

  const AdminTab = () => {
    if (userIsAdmin()) {
      return <Tab className={classes.tab} label="Verwaltung" to="/administrator" component={Link} />
    }

    return <></>
  }
    
  return (
    <div className={classes.NavigationBar}>
      <div className={classes.navContainer}>
        <div>
          <img src={logo} className={classes.logo} alt="BTC Logo" />
        </div>
        <div className={classes.navButtonContainer}>
          <Tabs value={tabIndex} onChange={handleChange} TabIndicatorProps={{ style: {background: underlineColor}}}>
            <Tab className={classes.tab} label="Stimmung abgeben" to="/" component={Link} />
            <Tab className={classes.tab} label="Übersicht" to="/overview" component={Link} />
            <AdminTab />
          </Tabs>
        </div>
        <div>
          <Button type="button" onClick={signOut} className={classes.authButton}>Abmelden</Button>
        </div>
        <div className={classes.mobileMenu}>
          <MobileMenuComponent />
        </div>
      </div>
    </div>
  );
}

export default NavigationBar;
